html, body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #f6f6f6;
}

* {
  box-sizing: border-box;
}

header {
  display: flex;
  justify-content: center;
  background-color: black;
  max-width: 480px;
  margin: 0 auto;
}

header img {
  width: 480px;
}

main {
  justify-content: center;
}

/* Panels */

.action-panel, .output-panel {
  line-height: 1.4em;
  display: flex;
  height: 350px;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 1.5em 2em;
}

.output-panel {
  display: none;
  justify-content: flex-start;
  border: 2px solid;
  padding: 1em 2em;
}

.loading-panel {
  display: none;
  flex-direction: column;
  justify-content: space-around;
  height: 400px;
  text-align: center;
}

.output-panel p{
  overflow-y: scroll;
}

/* text */

h2 {
  text-align: center;
  font-weight: 400; 
  margin-top: -26px;
  background-color: #f6f6f6;
  padding: 0 10px;
  font-size: 18px;
  margin-bottom: 0;
}

p.tag-line {
  font-family: 'Comic Neue';
  font-weight: 700;
  font-size: 14px;
}

.ticker-choice-display {
  display: flex;
  align-items: center;
  height: 3em; 
}

/* controls */

form {
  width: 360px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

label {
  text-align: center;
  padding: .43em;
  font-size: 15px;
  margin-bottom: 1em;
  width: 80%;
}

.form-input-control {
  display: flex;
  width: 70%;
}

input[type="text"] {
  padding: 1em;
  border: 2px solid black;
  border-right: none;
}

/* Buttons */

.add-ticker-btn {
  display: flex;
  align-items: center;
  background-color: #ffffff;
  font-size: 3em;
  padding: 0 .35em;
  cursor: pointer;
  border: 2px solid;
}

.add-ticker-svg {
  width: 14px;
}

.generate-report-btn {
  width: 250px;
  padding: 1em 1.5em;
  cursor: pointer;
  font-family: 'Poppins', sans;
  border: 2px solid #000000;
  background-color: #46ff90;
  text-transform: uppercase;
  font-weight: 500;
  letter-spacing: .09em;
  font-size: 105%;
}

/* ticker display (ensure comma separated) */

.ticker {
  margin: 0 .1em;
  content: '';
}

.ticker:after {
  content: ',';
}

.ticker:last-child::after {
  content: '';
}

/* footer */

footer {
  font-size: 14px;
  text-align: center;
}

/* stop ugly scroll bar on some browsers */
.output-panel p::-webkit-scrollbar {
  display: none;
}

.output-panel p::-moz-scrollbar {
  display: none;
}

.report {
  margin: 0 34%;
  text-align: center;
  font-size: 16px;
  border: 3px solid black;
  min-height: 100px;
}

.report h2 {
  font-size: 20px;
  font-weight: bold;
  margin-top: 10px;
}

.report p {  
  margin: 30px 40px;
  text-align: justify;
  text-justify: inter-word;
}